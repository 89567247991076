import React from "react";
import Image from "next/image";
import Stepicon1 from "@/public/static/images/step_icon-1.png";
import Stepicon2 from "@/public/static/images/step_icon-2.png";
import Stepicon3 from "@/public/static/images/step_icon-3.png";
import Link from "next/link";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

function MainStep() {
  return (
    <div className="row row-cols-1 row-cols-md-3 g-4">
      <div className="col">
        <Link href="/instrument">
          <div className="card h-100 card-step">
            <Image src={Stepicon1} alt="Stamping​" width={300} loading="lazy" />
            <div className="card-body">
              <h5 className="card-title fw-bold">プレス加工</h5>
              <div className="hstack gap-5">
                <div className="step_content">
                  <span>絞りプレスを中心とし、難易度の高い形状に対応 </span>
                </div>
                <div className="step_link ms-auto">
                  <Button variant="contained">
                    <KeyboardArrowRightIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div className="col">
        <Link href="/instrument">
          <div className="card h-100 card-step">
            <Image src={Stepicon2} alt="CNC Machining???" width={300} loading="lazy"/>
            <div className="card-body">
              <h5 className="card-title fw-bold">切削加工</h5>
              <div className="hstack gap-5">
                <div className="step_content">
                  <span>高精度加工品を安定した品質により供給</span>
                </div>
                <div className="step_link ms-auto">
                  <Button variant="contained">
                    <KeyboardArrowRightIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div className="col">
        <Link href="/instrument">
          <div className="card h-100 card-step">
            <Image src={Stepicon3} alt="Anodizing​​" width={300} loading="lazy"/>
            <div className="card-body">
              <h5 className="card-title fw-bold">アルマイト加工</h5>
              <div className="hstack gap-5">
                <div className="step_content">
                  <span>
                    自動車部品から高品位オーディオ部品まで無色・着色アルマイトに対応
                  </span>
                </div>
                <div className="step_link ms-auto">
                  <Button variant="contained">
                    <KeyboardArrowRightIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default MainStep;
