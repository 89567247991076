import React from "react";
import Header from "@/components/jp/Header";
import Menu from "@/components/jp/Menu";
import Footer from "@/components/jp/Footer";
import MainProcess from "@/components/jp/MainProcess";
import MainStep from "@/components/jp/MainStep";
import NewsList from "@/components/jp/NewsList";
import FloatingButton from "@/components/jp/FloatingButton";
import ScrollToTopFab from "@/components/jp/ScrollToTopFab";

import EmblaCarousel from "@/components/jp/EmblaCarousel";
import { EmblaOptionsType } from "embla-carousel-react";

import Link from "next/link";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CircleIcon from "@mui/icons-material/Circle";
import Stack from "@mui/material/Stack";
import Image from "next/image";
import SAbout from "@/public/static/images/opacity_about.png";
import SComNet from "@/public/static/images/opacity_company_network.png";
import SContact from "@/public/static/images/opacity_contact.png";

const OPTIONS: EmblaOptionsType = { loop: true };
// const SLIDE_COUNT = 1;
// const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

function index({ banner, news }: any) {
  const SLIDE_DATA = banner.data;
  const SLIDE_COUNT = banner.total;
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys());
  const NEWS_DATA = news.data;
  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <section className="carousel">
        <EmblaCarousel slides={SLIDES} options={OPTIONS} data={SLIDE_DATA} />
      </section>
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="heading">
                <h1 className="heading_inner fw-bold text-center mb-4">
                  加工
                  <CircleIcon
                    sx={{ mx: 2, fontSize: "14px", color: "#0179CC" }}
                  />
                  <span className="t-blue">用途紹介</span>
                </h1>
              </div>
            </div>
            <div className="col-12 mb-5">
              <MainProcess />
            </div>
          </div>
        </div>
        <div className="step_main py-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-12 mt-2 mb-4">
                <div className="heading">
                  <h1 className="heading_inner fw-bold text-center mb-4">
                    高い技術力で<span className="t-blue">一拠点一貫加工</span>
                  </h1>
                </div>
                <div className="step_text text-center">
                  <ul className="justify-content-center ">
                    <li>
                      弊社の強みは金属プレス、異形切削からカラーアルマイトまで一貫で社内で対応できるところにあります。
                    </li>
                    <li>
                      プレスの中でも特に絞りと呼ばれる立体成形プレスを得意にしており、高精度・高品位製品をタイで作ってきた実績があります。
                    </li>
                    <li>アルミ、ステンレスを中心に加工しております。</li>
                    <li>単独工程案件にも対応しておりますのでご相談下さい。</li>
                  </ul>
                </div>
              </div>
              <div className="col-12 mb-4">
                <div className="Subheading">
                  <h2 className="subheading_inner text-center mb-4">
                    設備概要
                  </h2>
                </div>
              </div>
              <div className="col-12">
                <MainStep />
                <div className="col-12 my-5 text-center">
                  <Link href="/instrument" className="btn-link">
                    <Button
                      variant="contained"
                      endIcon={<KeyboardArrowRightIcon />}
                    >
                      設備概要一覧へ
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="heading-news">
                <div className="hstack gap-3 heading-news_inner">
                  <div className="heading-sub">
                    <h4 className="fw-bold mb-0">
                      ニュ<span className="t-blue">ース</span>
                    </h4>
                  </div>
                  <div className="ms-auto">
                    <Link className="link-normal" href="/news">
                      ニュース一覧へ
                      <KeyboardArrowRightIcon />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mb-5">
              <NewsList data={NEWS_DATA} />
            </div>
          </div>
        </div>
      </section>
      <section className="sub-contact">
        <div className="sub-contact-inner">
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 0, sm: 0, md: 0 }}
            justifyContent="center"
            alignItems="center"
          >
            <Link href="/aboutus">
              <div className="card card-subcontact bg-darkblue">
                <Image
                  loading="lazy"
                  src={SAbout}
                  alt="About Us"
                  className="card-img"
                  width={300}
                />
                <div className="card-img-overlay d-flex justify-content-center align-items-center">
                  <h1 className="mb-0 fw-bold">会社概要</h1>
                </div>
              </div>
            </Link>
            <Link href="/companynetwork">
              <div className="card card-subcontact bg-blue">
                <Image
                  loading="lazy"
                  src={SComNet}
                  alt="About Us"
                  className="card-img"
                  width={300}
                />
                <div className="card-img-overlay d-flex justify-content-center align-items-center">
                  <h1 className="mb-0 fw-bold">関連会社紹介</h1>
                </div>
              </div>
            </Link>
            <Link href="/contactus">
              <div className="card card-subcontact bg-darkblue">
                <Image
                  loading="lazy"
                  src={SContact}
                  alt="About Us"
                  className="card-img"
                  width={300}
                />
                <div className="card-img-overlay d-flex justify-content-center align-items-center">
                  <h1 className="mb-0 fw-bold">お問い合わせ</h1>
                </div>
              </div>
            </Link>
          </Stack>
        </div>
      </section>
      <FloatingButton />
      <ScrollToTopFab />
      <Footer />
    </div>
  );
}
export async function getServerSideProps(context: any) {
  const url = process.env.NEXT_PUBLIC_BASE_URL_API;

  let banner = await fetch(`${url}/v1/banner`)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      return Promise.reject(response);
    })
    .catch((error) => {});

  if (!banner) {
    banner = { data: [] };
  }

  let news = await fetch(`${url}/v1/news`)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .catch((error) => {
      console.log(error);
    });
  if (!news) {
    news = { data: [] };
  }

  return {
    props: {
      banner: {
        total: banner.data.length,
        data: banner.data,
      },
      news: {
        total: news.data.length,
        data: news.data,
      },
    },
  };
}
export default index;
