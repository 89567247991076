import React, { useState, useEffect, useCallback } from "react";
import useEmblaCarousel, { EmblaOptionsType } from "embla-carousel-react";
import Image from "next/image";
import Link from "next/link";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  DotButton,
  PrevButton,
  NextButton,
} from "@/components/EmblaCarouselArrowsDotsButtons";
import Autoplay from "embla-carousel-autoplay";
import ImagebyBanner from "@/pages/ImagebyBanner";

type PropType = {
  slides: number[];
  options?: EmblaOptionsType;
};

const EmblaCarousel = (props: any) => {
  const { slides, options, data } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay()]);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );
  const scrollTo = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on("select", onSelect);
    emblaApi.on("reInit", onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  return (
    <>
      <div className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {slides.map((index: any) => {
              return (
                <div className="embla__slide" key={index}>
                  <div className="embla__slide__text embla__slide__text-jp">
                    <div className="text-banner_inner">
                      <h1 className="fw-bold">
                        Welcome To <br />
                        ZENIYA (Thailand)
                      </h1>
                      <p className="fs-6 mt-3">
                        「アルミの成形プレスをできる所はないか」
                        <br />
                        「アルマイト色を客先要求に合わせたい」
                        <br />
                        ​「マシニング加工できる外注はないか」
                        <br />​ といった声にお応えします
                      </p>
                      <Link href="/aboutus">
                        <Button
                          variant="contained"
                          endIcon={<KeyboardArrowRightIcon />}
                        >
                          会社概要
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <Image
                    className="embla__slide__img"
                    src={data[index].file}
                    alt={data[index].topic}
                    width={0}
                    height={0}
                    sizes="100vw"
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                    loading="lazy"
                  />
                </div>
              );
            })}
          </div>
        </div>

        <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
        <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
      </div>

      <div className="embla__dots">
        {scrollSnaps.map((_, index) => (
          <DotButton
            key={index}
            selected={index === selectedIndex}
            onClick={() => scrollTo(index)}
          />
        ))}
      </div>
    </>
  );
};

export default EmblaCarousel;
