import React from "react";
import Link from "next/link";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Image from "next/image";
import P1 from "@/public/static/images/main_p_1.png";
import P2 from "@/public/static/images/main_p_2.png";
import P3 from "@/public/static/images/main_p_3.png";
import P4 from "@/public/static/images/main_p_4.png";
import P5 from "@/public/static/images/main_p_5.png";

function MainProcess() {
  return (
    <div className="row">
      <div className="col-12 col-sm-6 box-product_main mb-3">
        <Link href="/processusage#stamping" className="card-product">
          <div className="card text-bg-dark h-100 ">
            <Image src={P1} alt="Zeniya" className="card-img" width={300} loading="lazy" />
            <div className="card-img-overlay">
              <h4 className="card-title mb-0">プレス加工品</h4>
            </div>
            <div className="box-more  box-more_left">
              <Button variant="contained">+</Button>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-12 col-sm-6 box-product_main mb-3">
        <Link href="/processusage#machining" className="card-product">
          <div className="card text-bg-dark h-100 ">
            <Image src={P2} alt="Zeniya" className="card-img" width={300} loading="lazy"/>
            <div className="card-img-overlay">
              <h4 className="card-title mb-0 text-end">切削加工品</h4>
            </div>
            <div className="box-more box-more_right">
              <Button variant="contained">+</Button>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-12 col-sm-6 box-product_main mb-3">
        <Link href="/processusage#coloranodized" className="card-product">
          <div className="card text-bg-dark h-100 ">
            <Image src={P3} alt="Zeniya" className="card-img" width={300} loading="lazy"/>
            <div className="card-img-overlay">
              <h4 className="card-title mb-0">アルマイト着色加工品</h4>
            </div>
            <div className="box-more box-more_left">
              <Button variant="contained">+</Button>
            </div>
          </div>
        </Link>
      </div>
      {/* <div className="col-12 col-sm-6 col-md-4 box-product_main mb-3">
        <Link href="/processusage#subprocesses" className="card-product">
          <div className="card text-bg-dark h-100 ">
            <Image src={P4} alt="Zeniya" className="card-img" width={300} />
            <div className="card-img-overlay">
              <h4 className="card-title mb-0 text-end">Sub Processes</h4>
            </div>
            <div className="box-more box-more_right">
              <Button variant="contained">+</Button>
            </div>
          </div>
        </Link>
      </div> */}
      <div className="col-12 col-sm-6 box-product_main mb-3">
        <Link href="/processusage#plantinfrastructure" className="card-product">
          <div className="card text-bg-dark h-100 ">
            <Image src={P5} alt="Zeniya" className="card-img" width={300} loading="lazy"/>
            <div className="card-img-overlay">
              <h4 className="card-title mb-0 text-end t-s">
                プラント・インフラ向け機器
              </h4>
            </div>
            <div className="box-more box-more_right">
              <Button variant="contained">+</Button>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-12 my-5 text-center">
        <Link href="/processusage" className="btn-link">
          <Button variant="contained" endIcon={<KeyboardArrowRightIcon />}>
            加工・用途紹介一覧へ
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default MainProcess;
